import { Button, Checkbox, Form, Input } from 'antd';
import './LoginForm.css';
import { Link } from 'react-router-dom';

interface LogingFormProps {
  onFinish: (values: { email: string; password: string }) => void;
  onFinishFailed: (errorInfo: any) => void;
  loading: boolean;
}

export default function LoginForm({
  onFinish,
  onFinishFailed,
  loading,
}: LogingFormProps) {
  return (
    <div className='login-wrapper'>
      <Form
        name='basic'
        layout='vertical'
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete='off'
      >
        <Form.Item
          label='Email'
          name='email'
          rules={[{ required: true, message: 'Please input your email!' }]}
        >
          <Input placeholder='Enter your email' className='input' />
        </Form.Item>

        <Form.Item
          label='Password'
          name='password'
          rules={[{ required: true, message: 'Please input your password!' }]}
        >
          <Input.Password placeholder='Enter your password' className='input' />
        </Form.Item>

        <Form.Item name='remember'>
          <div className='inline'>
            <Checkbox>Remember me</Checkbox>
            <Link to='/reset-password'>Forgot password</Link>
          </div>
        </Form.Item>

        <Form.Item>
          <Button
            type='primary'
            htmlType='submit'
            className='entry-button'
            loading={loading}
          >
            Sign in
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}
