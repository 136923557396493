import * as React from 'react';
import { ReactComponent as Logo } from 'src/app/asset/images/rentfree-logo.svg';
import { Button, Form, Input, Select } from 'antd';
import ThirdParties from 'src/app/core/components/entry-wrapper/ThirdParties';

import showMessage from 'src/app/core/utils/show-message';
import { useAppDispatch } from 'src/app/core/store/hooks';
import { thunkSignup } from '../../store/thunks';

import './SignupForm.css';
import useAuthUser from 'src/app/hooks/useAuthUser';

interface Props {
  changeHandler: (userEmail: string, verificationHash: string) => void;
}

export default function SignupForm(props: Props) {
  const { authLoading } = useAuthUser();
  const { changeHandler } = props;
  const dispatch = useAppDispatch();

  const onFinish = (values: any) => {
    dispatch(thunkSignup(values))
      .unwrap()
      .then((data) => {
        console.log(data);
        changeHandler(data.email, data.verificationHash!);
      })
      .catch((error) => {
        showMessage('error', error.message ?? 'login failed');
      });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <div className='signin-container'>
      <a href='/'>
        <Logo />
      </a>
      <div className='signup'>
        <p className='signup-text'>Sign up</p>
        <p className='signup-details'>To sign up, fill in your personal details below</p>

        {/* Signup Form */}

        <div className='signupform'>
          <Form
            name='basic'
            layout='vertical'
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete='off'
          >
            <div className='form-group'>
              <Form.Item
                label='First Name'
                name='firstName'
                rules={[{ required: true, message: 'Please input your first name!' }]}
              >
                <Input placeholder='First Name' className='input' />
              </Form.Item>

              <Form.Item
                label='Last Name'
                name='lastName'
                rules={[{ required: true, message: 'Please input your last name!' }]}
              >
                <Input placeholder='Last Name' className='input' />
              </Form.Item>
            </div>

            <div className='form-group'>
              <Form.Item
                label='Select a role'
                name='role'
                rules={[{ required: true, message: 'Please select a role' }]}
              >
                <Select
                  placeholder='Select a role'
                  optionFilterProp='label'
                  style={{ width: 175 }}
                  // onChange={onChange}
                  options={[
                    {
                      value: 'agent',
                      label: 'Agent',
                    },
                    {
                      value: 'client',
                      label: 'Client',
                    },
                    {
                      value: 'landlord',
                      label: 'Landlord',
                    },
                  ]}
                />
              </Form.Item>
              <Form.Item
                label='Phone number'
                name='phone'
                rules={[{ required: true, message: 'Phone number is required' }]}
              >
                <Input type='number' placeholder='Phone number' className='input' />
              </Form.Item>
            </div>

            <Form.Item
              label='Email'
              name='email'
              rules={[{ required: true, message: 'Please input your email!' }]}
            >
              <Input placeholder='Enter your email' className='input' />
            </Form.Item>

            <Form.Item
              label='Password'
              name='password'
              rules={[{ required: true, message: 'Please input your password!' }]}
              extra=' Must be at least 8 characters.'
            >
              <Input.Password placeholder='Enter your password' className='input' />
            </Form.Item>

            <Form.Item>
              <Button
                type='primary'
                htmlType='submit'
                className='entry-button'
                loading={authLoading === true}
              >
                Get Started
              </Button>
            </Form.Item>
          </Form>
        </div>

        <ThirdParties page='sign up' />

        <div className='login'>
          Already have an account? <a href='/login'>Login</a>
        </div>
      </div>
    </div>
  );
}
