import { createAsyncThunk } from '@reduxjs/toolkit';
import { User } from 'rentfree-api';
import { client } from 'src/app/services/client';
import { Paginated } from '@feathersjs/feathers';

export const createUserThunk = createAsyncThunk(
  'users/createUser',
  async (user: User) => {
    return client.service('users').create(user);
  }
);

export const getUsersThunk = createAsyncThunk<Paginated<User>, void>(
  'users/getUsers',
  async () => {
    return client.service('users').find();
  }
);

export const getUserThunk = createAsyncThunk<User, string>(
  'users/getUser',
  async (id: string) => {
    return client.service('users').get(id);
  }
);

export const updateUserThunk = createAsyncThunk(
  'users/updateUser',
  async (user: User) => {
    return client.service('users').patch(user.id, user);
  }
);

export const deleteUserThunk = createAsyncThunk(
  'users/deleteUser',
  async (id: string) => {
    return client.service('users').remove(id);
  }
);
