import useQueryParams from 'src/app/hooks/useQueryParams';
import { SearchBox } from '../../core/components';

import './Home.css';
import { GetPropertiesQuery } from 'src/app/core/types/property';

export default function Home() {
  const { queryParams, updateQueryParams } = useQueryParams<GetPropertiesQuery>();
  return (
    <div className='home-container'>
      <div className='home-content'>
        <h3 className='home-header-text'>Hey! What Are You Looking For ?</h3>
        <SearchBox setQuery={updateQueryParams} queryParams={queryParams} />
      </div>
    </div>
  );
}
