import { Col, Input, Form } from 'antd';

export default function LocationDetails() {
  return (
    <>
      <Col xs={{ span: 24 }} lg={{ span: 12 }}>
        <Form.Item
          name='address'
          label='Address'
          rules={[{ required: true, message: 'Please input your address' }]}
        >
          <Input type='text' placeholder='Enter address' />
        </Form.Item>
      </Col>
      <Col xs={{ span: 24 }} lg={{ span: 6 }}>
        <Form.Item
          name='country'
          label='Country'
          rules={[{ required: true, message: 'Please input your Country' }]}
        >
          <Input type='text' placeholder='Enter country' />
        </Form.Item>
      </Col>
      <Col xs={{ span: 24 }} lg={{ span: 6 }}>
        <Form.Item
          name='city'
          label='City'
          rules={[{ required: true, message: 'Please input your City' }]}
        >
          <Input type='text' placeholder='Enter city' />
        </Form.Item>
      </Col>
      <Col xs={{ span: 24 }} lg={{ span: 8 }}>
        <Form.Item
          name='state'
          label='State'
          rules={[{ required: true, message: 'Please input your State' }]}
        >
          <Input type='text' placeholder='Enter state' />
        </Form.Item>
      </Col>
      <Col xs={{ span: 24 }} lg={{ span: 8 }}>
        <Form.Item
          name='lga'
          label='lga'
          rules={[{ required: true, message: 'Please input your lga' }]}
        >
          <Input type='text' placeholder='Enter lga' />
        </Form.Item>
      </Col>
      <Col xs={{ span: 24 }} lg={{ span: 8 }}>
        <Form.Item
          name='zipcode'
          label='zipcode'
          rules={[{ required: true, message: 'Please input your zipcode' }]}
        >
          <Input type='number' placeholder='Enter zipcode' />
        </Form.Item>
      </Col>
      <Col xs={{ span: 24 }} lg={{ span: 12 }}>
        <Form.Item
          name='latitude'
          label='Latitude'
          rules={[{ required: true, message: 'Please input your Latitude' }]}
        >
          <Input type='text' placeholder='Enter latitude' />
        </Form.Item>
      </Col>
      <Col xs={{ span: 24 }} lg={{ span: 12 }}>
        <Form.Item
          name='longitude'
          label='Longitude'
          rules={[{ required: true, message: 'Please input your Longitude' }]}
        >
          <Input type='text' placeholder='Enter longitude' />
        </Form.Item>
      </Col>
    </>
  );
}
