/**
 * Decode string
 */

import dayjs from 'dayjs';

export const decodeString = (str: string) =>
  decodeURIComponent(
    Array.prototype.map
      .call(atob(str), (c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
      .join('')
  );

/**
 * Decode string
 */
export const encodeString = (str: string) =>
  btoa(
    encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, (_, p1) =>
      String.fromCharCode(parseInt(p1, 16))
    )
  );

// Function to filter out keys with undefined values
export const filterUndefinedValues = (obj: {}) => {
  return Object.fromEntries(
    Object.entries(obj).filter(([_, value]) => value !== undefined)
  );
};

export function replaceSpacesWithPlus(str: string) {
  return str.replace(/ /g, '+');
}

export function getDetailedRelativeTime(dateString: string): string {
  const now = dayjs();
  const date = dayjs(dateString);

  const days = now.diff(date, 'day');
  const hours = now.diff(date, 'hour') % 24;
  const minutes = now.diff(date, 'minute') % 60;

  let result = '';

  if (days > 0) result += `${days} day${days > 1 ? 's' : ''} `;
  if (hours > 0) result += `${hours} hr${hours > 1 ? 's' : ''} `;
  if (minutes > 0) result += `${minutes} min${minutes > 1 ? 's' : ''} `;

  return result.trim() + ' ago';
}
export const truncateString = (text: string, length: number = 10) => {
  return `${text.substring(0, length)}...${text.substring(text.length - length + 3)}`;
};
