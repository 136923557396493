import { Button, Card, Col, Form, Row, Steps } from 'antd';
import { useState } from 'react';
import BasicDetails from './components/BasicDetails';
import LocationDetails from './components/LocationDetails';
import Media from './components/Media';
import FinancialDetails from './components/FinancialDetails';
import { useAppDispatch, useAppSelector } from 'src/app/core/store/hooks';
import { thunkCreateProperty } from './store/thunks';
import { useNavigate } from 'react-router-dom';
import showMessage from 'src/app/core/utils/show-message';
import { selectProperties } from './store/adminPropertySlice';

export default function ListProperty() {
  const navigate = useNavigate();
  const [current, setCurrent] = useState(0);
  const [form] = Form.useForm();
  const [formData, setFormData] = useState({
    isServiced: false,
    status: 'available',
  });
  const dispatch = useAppDispatch();
  const { isLoading } = useAppSelector(selectProperties);

  const onFinish = (values: any) => {
    dispatch(thunkCreateProperty(values))
      .unwrap()
      .then((data) => {
        navigate('/dashboard/properties');
        showMessage('success', 'Property created successfully');
      })
      .catch((error) => {
        showMessage('error', error.message ?? 'unable to create property');
      });
  };

  const next = async () => {
    try {
      const values = await form.validateFields();
      setFormData((prevData) => ({ ...prevData, ...values }));
      setCurrent((prev) => prev + 1);
      form.setFieldsValue({ ...formData, ...values });
    } catch (error) {
      console.log('Validation failed:', error);
    }
  };

  const prev = () => {
    setCurrent((prev) => prev - 1);
    form.setFieldsValue(formData);
  };

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      const finalData = { ...formData, ...values };
      onFinish(finalData);
    } catch (error) {
      console.log('Validation failed:', error);
    }
  };

  const steps = [
    {
      title: 'Basic Information',
      content: <BasicDetails />,
    },
    {
      title: 'Financial Details',
      content: <FinancialDetails />,
    },
    {
      title: 'Location',
      content: <LocationDetails />,
    },
    {
      title: 'Media',
      content: <Media />,
    },
  ];

  return (
    <Row>
      <Col offset={3} span={18}>
        <Steps
          current={current}
          items={steps}
          style={{ marginBottom: 40 }}
          progressDot
          responsive
        />
        <Card>
          <Form
            form={form}
            layout='vertical'
            onFinish={onFinish}
            initialValues={{
              isFurnished: false,
              hasGarden: false,
              hasWater: false,
              hasElectricity: false,
            }}
          >
            <Row gutter={[16, 16]}>{steps[current].content}</Row>
            <Row style={{ marginTop: 24, justifyContent: 'flex-start', columnGap: 10 }}>
              {current > 0 && <Button onClick={() => prev()}>Previous</Button>}
              {current < steps.length - 1 && (
                <Button type='primary' onClick={() => next()}>
                  Next
                </Button>
              )}
              {current === steps.length - 1 && (
                <Button
                  type='primary'
                  onClick={handleSubmit}
                  loading={isLoading === true}
                >
                  Done
                </Button>
              )}
            </Row>
          </Form>
        </Card>
      </Col>
    </Row>
  );
}
