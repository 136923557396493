import { Row, Spin } from 'antd';
import { SearchBox } from 'src/app/core/components';
import useQueryParams from 'src/app/hooks/useQueryParams';
import PropertyList from './components/PropertyList';
import { useAppDispatch, useAppSelector } from 'src/app/core/store/hooks';
import { selectGetProperties } from './store/propertySlice';
import { thunkGetProperties } from './store/thunk';
import { GetPropertiesQuery } from 'src/app/core/types/property';
import useDeepCompareEffect from 'src/app/hooks/useDeepCompareEffect';
import { LoadingOutlined } from '@ant-design/icons';

export default function Property() {
  const { queryParams, updateQueryParams } = useQueryParams<GetPropertiesQuery>();

  const dispatch = useAppDispatch();
  const { properties, loading } = useAppSelector(selectGetProperties);

  const propertiesData = properties.data ?? [];

  useDeepCompareEffect(() => {
    dispatch(thunkGetProperties(queryParams)).unwrap();
  }, [dispatch, queryParams]);

  return (
    <div>
      <div className='nav-search home page-content home-screen-padding'>
        <SearchBox setQuery={updateQueryParams} queryParams={queryParams} />
      </div>

      <Row
        justify='center'
        className='home page-content home-screen-padding'
        gutter={[0, 0]}
      >
        <div
          style={{
            marginTop: '24px',
          }}
        >
          {loading ? (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginTop: '100px',
                marginBottom: '100px',
                justifyContent: 'center',
              }}
            >
              <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
            </div>
          ) : (
            <>
              {propertiesData?.length > 0 ? (
                <PropertyList properties={properties} />
              ) : (
                <div>property not found</div>
              )}
            </>
          )}
        </div>
      </Row>
    </div>
  );
}
