import { Form, Select, Button, Col, AutoComplete } from 'antd';
import { ControlOutlined, SearchOutlined } from '@ant-design/icons';
import './SearchBox.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { filterUndefinedValues } from '../../utils/helpers';
import useModalState from 'src/app/hooks/useModalState';
import AdvancedSearchDrawer from './components/AdvancedSearchDrawer';
import { GetPropertiesQuery } from '../../types/property';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { thunkLgaSearch } from 'src/app/features/property/store/thunk';
import { selectLgaSearchResults } from 'src/app/features/property/store/propertySlice';

interface Props {
  setQuery: (q: GetPropertiesQuery) => void;
  queryParams: GetPropertiesQuery;
}

export function SearchBox(props: Props) {
  const { isOpen, closeModal, openModal } = useModalState();
  const searchResults = useAppSelector(selectLgaSearchResults);

  const { setQuery, queryParams } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const isSearchPage = location.pathname === '/property';
  const dispatch = useAppDispatch();

  const handleSearchQuery = (values: GetPropertiesQuery) => {
    if (isSearchPage) {
      setQuery({ ...values });
    } else {
      let filteredQueryObj = filterUndefinedValues(values);
      let queryParams = new URLSearchParams({ ...(filteredQueryObj as {}) });
      Object.values(filteredQueryObj).length > 0 && setQuery({ ...values });
      Object.values(filteredQueryObj).length > 0
        ? navigate(`/property?${queryParams.toString()}`, {
            state: { ...filteredQueryObj },
          })
        : navigate('/');
    }
  };

  const stateLgaSearch = (e: string) => {
    dispatch(thunkLgaSearch({ $search: e }));
  };

  return (
    <>
      <Form layout='vertical' className='search-form' onFinish={handleSearchQuery}>
        <div className='search-box'>
          <div className='search-box__item'>
            <Form.Item
              initialValue={queryParams.location}
              name={'location'}
              className='first-item'
              label={'Location'}
            >
              <AutoComplete
                options={searchResults}
                placeholder='Enter location'
                onSearch={stateLgaSearch}
              />
            </Form.Item>
          </div>
          <Col className='search-box__item responsive' span={5}>
            <Form.Item
              initialValue={queryParams.propertyType}
              name={'propertyType'}
              label={'Property Type'}
            >
              <Select
                placeholder='Select type'
                options={[
                  { label: 'House', value: 'house' },
                  { label: 'Apartment', value: 'apartment' },
                  { label: 'Guesthouse', value: 'guesthouse' },
                ]}
              />
            </Form.Item>
          </Col>
          <Col className='search-box__item responsive' span={3}>
            <Form.Item
              initialValue={queryParams.minBed}
              name={'minBed'}
              label={'No. of beds'}
            >
              <Select
                placeholder='Min beds'
                options={[
                  { label: '1', value: 1 },
                  { label: '2', value: 2 },
                  { label: '3', value: 3 },
                  { label: '4', value: 4 },
                  { label: '5', value: 5 },
                ]}
              />
            </Form.Item>
          </Col>
          <div className='range-separator' />
          <Col className='search-box__item responsive' span={3}>
            <Form.Item
              initialValue={queryParams.maxBed}
              name={'maxBed'}
              label={' '}
              rules={[
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('minBed') < value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error('Max bed must be greater than min bed')
                    );
                  },
                }),
              ]}
            >
              <Select
                placeholder='Max beds'
                size='small'
                options={[
                  { label: '1', value: 1 },
                  { label: '2', value: 2 },
                  { label: '3', value: 3 },
                  { label: '4', value: 4 },
                  { label: '5', value: 5 },
                ]}
              />
            </Form.Item>
          </Col>
          <Col className='search-box__item responsive range-fieldx' span={3}>
            <Form.Item
              initialValue={queryParams.minPrice}
              name={'minPrice'}
              label={'Price range'}
            >
              <Select
                placeholder='Min price'
                options={[
                  { label: '$1,000', value: 1000 },
                  { label: '$1,500', value: 1500 },
                ]}
              />
            </Form.Item>
          </Col>
          <div className='range-separator' />
          <Col className='search-box__item responsive' span={3}>
            <Form.Item
              initialValue={queryParams.maxPrice}
              name={'maxPrice'}
              label={' '}
              rules={[
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('minPrice') < value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error('Max price must be greater than min price')
                    );
                  },
                }),
              ]}
            >
              <Select
                placeholder='Max price'
                options={[
                  { label: '$1,000', value: 1000 },
                  { label: '$1,500', value: 1500 },
                ]}
              />
            </Form.Item>
          </Col>
          {isSearchPage && (
            <div className='search-box__button'>
              <Form.Item label=' '>
                <Button icon={<ControlOutlined />} onClick={openModal}>
                  Filters
                </Button>
              </Form.Item>
            </div>
          )}
          {isSearchPage && (
            <div className='search-box__button'>
              <Form.Item label=' '>
                <Button type='primary' htmlType='submit' icon={<SearchOutlined />}>
                  Search
                </Button>
              </Form.Item>
            </div>
          )}
        </div>
        {!isSearchPage && (
          <div className='search-home-btn'>
            <Button type='primary' htmlType='submit' icon={<SearchOutlined />}>
              Search
            </Button>
          </div>
        )}
      </Form>

      <AdvancedSearchDrawer
        open={isOpen}
        onClose={closeModal}
        setQuery={setQuery}
        queryParams={queryParams}
      />
    </>
  );
}
