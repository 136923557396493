import { Select, Col, Input, DatePicker, Switch, Form } from 'antd';

export default function BasicDetails() {
  return (
    <>
      <Col xs={{ span: 24 }} lg={{ span: 12 }}>
        <Form.Item
          name='title'
          label='Title'
          rules={[{ required: true, message: 'Please input your title' }]}
        >
          <Input type='text' placeholder='3 bedroom flat at Ikeja Lagos' />
        </Form.Item>
      </Col>
      <Col xs={{ span: 24 }} lg={{ span: 6 }}>
        <Form.Item
          name='listingType'
          label='Listing Type'
          rules={[{ required: true, message: 'Listing Type is required' }]}
        >
          <Select
            placeholder='Select property type'
            options={[
              { label: 'Rent', value: 'rent' },
              { label: 'Sale', value: 'sale' },
            ]}
          />
        </Form.Item>
      </Col>
      <Col xs={{ span: 24 }} lg={{ span: 6 }}>
        <Form.Item
          name='propertyType'
          label='Property Type'
          rules={[{ required: true, message: 'Property Type is required' }]}
        >
          <Select
            placeholder='Select property type'
            options={[
              { label: 'House', value: 'house' },
              { label: 'Apartment', value: 'apartment' },
              { label: 'Land', value: 'land' },
              { label: 'Commercial', value: 'commercial' },
              { label: 'Other', value: 'other' },
            ]}
          />
        </Form.Item>
      </Col>
      <Col xs={{ span: 24 }} lg={{ span: 6 }}>
        <Form.Item
          name='tenure'
          label='Tenure'
          rules={[{ required: true, message: 'Tenure is required' }]}
        >
          <Select
            placeholder='Select tenure'
            options={[
              { label: 'Monthly', value: 'monthly' },
              { label: 'Yearly', value: 'yearly' },
              { label: 'Weekly', value: 'weekly' },
              { label: 'Daily', value: 'daily' },
            ]}
          />
        </Form.Item>
      </Col>
      <Col xs={{ span: 24 }} lg={{ span: 6 }}>
        <Form.Item
          name='bedrooms'
          label='Bedrooms'
          rules={[{ required: true, message: 'bedrooms is required' }]}
        >
          <Select
            placeholder='Select number of bedrooms'
            options={[
              { label: 'One', value: 1 },
              { label: 'Two', value: 2 },
              { label: 'Three', value: 3 },
              { label: 'Four', value: 4 },
              { label: 'Five', value: 5 },
            ]}
          />
        </Form.Item>
      </Col>
      <Col xs={{ span: 24 }} lg={{ span: 6 }}>
        <Form.Item
          name='bathrooms'
          label='Bathrooms'
          rules={[{ required: true, message: 'bathrooms is required' }]}
        >
          <Select
            placeholder='Select number of bathrooms'
            options={[
              { label: 'One', value: 1 },
              { label: 'Two', value: 2 },
              { label: 'Three', value: 3 },
              { label: 'Four', value: 4 },
              { label: 'Five', value: 5 },
            ]}
          />
        </Form.Item>
      </Col>
      <Col xs={{ span: 24 }} lg={{ span: 6 }}>
        <Form.Item label='Date Avalailable'>
          <DatePicker style={{ width: '100%' }} />
        </Form.Item>
      </Col>
      <Col xs={{ span: 24 }} lg={{ span: 24 }}>
        <Form.Item
          name={'description'}
          label='Description'
          rules={[{ required: true, message: 'Please input your description' }]}
        >
          <Input.TextArea
            showCount
            maxLength={1000}
            autoSize={{ minRows: 3, maxRows: 5 }}
          />
        </Form.Item>
      </Col>
      <Col xs={{ span: 24 }} lg={{ span: 6 }}>
        <Form.Item name='isFurnished' label='Furnished' valuePropName='checked'>
          <Switch />
        </Form.Item>
      </Col>
      <Col xs={{ span: 24 }} lg={{ span: 6 }}>
        <Form.Item name='hasGarden' label='Garden' valuePropName='checked'>
          <Switch />
        </Form.Item>
      </Col>
      <Col xs={{ span: 24 }} lg={{ span: 6 }}>
        <Form.Item name='hasWater' label='Water' valuePropName='checked'>
          <Switch />
        </Form.Item>
      </Col>
      <Col xs={{ span: 24 }} lg={{ span: 6 }}>
        <Form.Item name='hasElectricity' label='Electricity' valuePropName='checked'>
          <Switch />
        </Form.Item>
      </Col>
    </>
  );
}
