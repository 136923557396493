import { useEffect, useRef } from 'react';
import isEqual from 'lodash/isEqual';

/**
 * Custom hook that runs the effect only when the dependencies have deeply changed.
 */

function useDeepCompareEffect(callback: () => void, dependencies: any[]) {
  const previousDependenciesRef = useRef<any[]>([]);

  if (!isEqual(previousDependenciesRef.current, dependencies)) {
    previousDependenciesRef.current = dependencies;
  }

  useEffect(callback, [previousDependenciesRef.current]);
}

export default useDeepCompareEffect;
