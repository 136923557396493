import { createSlice } from '@reduxjs/toolkit';
import { User } from 'rentfree-api';
import { createUserThunk, getUsersThunk } from '../thunks/usersThunk';
import { RootState } from 'src/app/core/store/store';

interface UsersState {
  users: User[];
  loading: boolean | null;
  error: any;
}

const initialState: UsersState = {
  users: [],
  loading: null,
  error: null,
};

export const usersSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUsers: (state, action) => {
      state.users = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createUserThunk.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createUserThunk.fulfilled, (state, action) => {
      state.loading = false;
      if (state.users.findIndex((user) => user.id === action.payload.id) === -1) {
        state.users.unshift(action.payload);
      }
    });
    builder.addCase(createUserThunk.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
    builder.addCase(getUsersThunk.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getUsersThunk.fulfilled, (state, action) => {
      state.loading = false;
      state.users = action.payload.data;
    });
    builder.addCase(getUsersThunk.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
  },
});

export const { setUsers } = usersSlice.actions;
export const selectUsers = (state: RootState) => state.users.users;
export const selectLoading = (state: RootState) => state.users.loading;
export const selectError = (state: RootState) => state.users.error;

export default usersSlice.reducer;
