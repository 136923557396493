import * as React from 'react';
import EntryWrapper from 'src/app/core/components/entry-wrapper/EntryWrapper';
import SignupForm from './components/SignupForm';
import ComfirmCode from './components/ComfirmCode';

export default function Signup() {
  const [userEmail, setUserEmail] = React.useState<string | undefined>();
  const [verificationHash, setVerificationHash] = React.useState<string | undefined>();

  const changeHandler = (userEmail: string, verificationHash: string) => {
    setUserEmail(userEmail);
    setVerificationHash(verificationHash);
  };

  return (
    <EntryWrapper>
      {!userEmail ? (
        <SignupForm changeHandler={changeHandler} />
      ) : (
        <ComfirmCode userEmail={userEmail} verificationHash={verificationHash} />
      )}
    </EntryWrapper>
  );
}
