import { createAsyncThunk } from '@reduxjs/toolkit';
import { Property } from 'rentfree-api';
import { client } from 'src/app/services/client';

export const thunkCreateProperty = createAsyncThunk(
  'adminProperties/createProperty',
  async (payload: Property) => {
    const response = await client.service('properties').create({ ...payload });

    return response;
  }
);

export const thunkListProperties = createAsyncThunk(
  'adminProperties/getProperties',
  async (userId: number) => {
    const response = await client.service('properties').find({
      query: {
        userId: userId,
      },
    });
    return response.data;
  }
);

export const thunkGetProperty = createAsyncThunk(
  'adminProperties/getProperty',
  async (propertyId: string) => {
    const response = await client.service('properties').find({
      query: {
        propertyId: propertyId,
      },
    });
    return response.data;
  }
);

export const thunkPatchProperty = createAsyncThunk(
  'adminProperties/patchProperty',
  async (payload: { id: string; data: Partial<Property> }) => {
    const { id, data } = payload;

    const response = await client.service('properties').patch(id, data);
    return response;
  }
);

export const thunkDeleteProperty = createAsyncThunk(
  'adminProperties/DeleteProperty',
  async (propertyId: string) => {
    return client.service('properties').remove(propertyId);
  }
);
