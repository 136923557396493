import { Button, Card, Checkbox, Col, Form, Input, Row, Select } from 'antd';
import { useAppDispatch, useAppSelector } from 'src/app/core/store/hooks';
import { selectLoading } from '../store/usersSlice';
import { createUserThunk } from '../thunks/usersThunk';
import showMessage from 'src/app/core/utils/show-message';
import { UserAddOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

export default function CreateUser() {
  const loading = useAppSelector(selectLoading);
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
  };

  const createUser = (values: any) => {
    const { addMore, ...user } = values;
    user.password = 'password';

    dispatch(createUserThunk(user))
      .unwrap()
      .then((user) => {
        showMessage('success', 'User created successfully');
        if (!addMore) {
          navigate('/dashboard/users/' + user.id);
          return;
        }
        form.resetFields();
      })
      .catch((error) => {
        showMessage('error', 'Error creating user: ' + error.message);
      });
  };

  return (
    <Row>
      <Col lg={{ span: 12, offset: 6 }} sm={{ span: 24 }}>
        <Card title='Create User'>
          <Form {...layout} onFinish={createUser} form={form}>
            <Form.Item label='First Name' name='firstName' required>
              <Input type='text' placeholder='Enter first name' />
            </Form.Item>
            <Form.Item label='Last Name' name={'lastName'} required>
              <Input type='text' placeholder='Enter last name' />
            </Form.Item>
            <Form.Item
              label='Email'
              name={'email'}
              rules={[{ required: true, type: 'email' }]}
            >
              <Input type='email' placeholder='Enter email' />
            </Form.Item>
            <Form.Item
              label='Phone Number'
              name={'phone'}
              rules={[{ required: true, type: 'string' }]}
            >
              <Input type='text' placeholder='Enter phone number' />
            </Form.Item>
            <Form.Item label='User Type' required name={'role'}>
              <Select
                placeholder='Select user type'
                options={[
                  { label: 'Admin', value: 'admin' },
                  { label: 'Agent', value: 'agent' },
                  { label: 'User', value: 'user' },
                ]}
              />
            </Form.Item>
            <Row style={{ justifyContent: 'space-evenly' }}>
              <Col span={10} offset={2}>
                <Form.Item
                  required={false}
                  label={null}
                  valuePropName='checked'
                  name={'addMore'}
                >
                  {/* Option to create more users after subit */}
                  <Checkbox>Create more users</Checkbox>
                </Form.Item>
              </Col>
              <Col span={10} offset={2}>
                <Button
                  icon={<UserAddOutlined />}
                  className='btn btn-primary'
                  type='primary'
                  htmlType='submit'
                  loading={loading === true}
                  style={{ float: 'right' }}
                >
                  Create User
                </Button>
              </Col>
            </Row>
          </Form>
        </Card>
      </Col>
    </Row>
  );
}
