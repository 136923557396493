import { Outlet } from 'react-router-dom';
import LoadingScreen from 'src/app/core/components/LoadingScreen';
import { useAppDispatch } from 'src/app/core/store/hooks';
import { thunkLogout } from 'src/app/features/auth/store/thunks';
import useAuthUser from 'src/app/hooks/useAuthUser';
import { socket } from 'src/app/services/client';

export default function ProtectedRoutesGuard() {
  const { isAuthenticated, authLoading } = useAuthUser();
  const dispatch = useAppDispatch();

  if (isAuthenticated === false && authLoading === false) {
    dispatch(thunkLogout());
  }

  socket.on('disconnect', () => {
    console.log('Disconnected from server');
    dispatch(thunkLogout());
  });

  return (
    <>
      {authLoading && <LoadingScreen />}
      {isAuthenticated && !authLoading && (
        <div>
          <Outlet />
        </div>
      )}
    </>
  );
}
