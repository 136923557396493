import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import Message from 'src/app/asset/images/message.png';
import PinInput from 'react-pin-input';

import { ReactComponent as Logo } from 'src/app/asset/images/rentfree-logo.svg';
// import { ReactComponent as Info } from 'src/app/asset/icons/info.svg';
import './ComfirmCode.css';
import { useState } from 'react';
import showMessage from 'src/app/core/utils/show-message';
import { useAppDispatch } from 'src/app/core/store/hooks';
import { thunkVerifyEmail } from '../../store/thunks';
import useAuthUser from 'src/app/hooks/useAuthUser';

interface Props {
  userEmail: string;
  verificationHash?: string;
}

export default function ComfirmCode(props: Props) {
  const { userEmail, verificationHash } = props;
  const dispatch = useAppDispatch();
  const { authLoading } = useAuthUser();
  const navigate = useNavigate();
  const [confirmCode, setConfirmCode] = useState<string>();

  const HandleConfirmCode = () => {
    dispatch(
      thunkVerifyEmail({
        otp: confirmCode!,
        email: userEmail,
        verificationHash: verificationHash!,
      })
    )
      .unwrap()
      .then((data) => {
        navigate('/login');
        showMessage('success', 'signup successfully');
      })
      .catch((error) => {
        showMessage('success', error.message ?? 'login failed');
      });
  };

  return (
    <div className='comfirmCodeContainer'>
      <Logo />
      <br />
      <img src={Message} alt='message icon' />
      <div className='signup'>
        <p>Check your mail</p>
        <p>
          Please enter the 6 digit code sent to <span>{userEmail}</span>
        </p>
        <PinInput
          disabled={authLoading === true}
          length={6}
          initialValue=''
          onChange={(value) => setConfirmCode(value)}
          type='custom'
          inputStyle={{ marginRight: '8px' }}
          autoSelect={true}
        />
        <button
          className='entry-button'
          type='button'
          disabled={authLoading === true}
          onClick={HandleConfirmCode}
        >
          Confirm code
        </button>
        {/* <span>Didn’t get the code?</span> */}
        {/* <div className='resend'>
          <Info />
          <p>Resend code in 02:34</p>
        </div> */}
      </div>
    </div>
  );
}
