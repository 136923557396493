import * as React from 'react';
import { Button, Drawer, Form, Select, Space } from 'antd';
import { Tag } from 'antd';
import { GetPropertiesQuery } from 'src/app/core/types/property';

interface Props {
  open: boolean;
  onClose: VoidFunction;
  setQuery: (q: GetPropertiesQuery) => void;
  queryParams: GetPropertiesQuery;
}

const tagsData = [
  'Wifi',
  'Tv',
  'Washer',
  'Air Condition',
  'Free Parking',
  'Pool',
  'Hot Tub',
];

export default function AdvancedSearchDrawer(props: Props) {
  const { open, onClose, queryParams, setQuery } = props;
  const [form] = Form.useForm();

  const [selectedTags, setSelectedTags] = React.useState<string[]>(['Movies']);
  const handleChange = (tag: string, checked: boolean) => {
    const nextSelectedTags = checked
      ? [...selectedTags, tag]
      : selectedTags.filter((t) => t !== tag);
    setSelectedTags(nextSelectedTags);
  };

  const handleSearchQuery = (values: GetPropertiesQuery) => {
    setQuery({ ...values });
    onClose();
  };

  const handleOnClose = () => {
    form.resetFields();
    setSelectedTags([]);
    onClose();
  };

  return (
    <Drawer
      title={'Property Search'}
      placement='right'
      closable={false}
      onClose={onClose}
      open={open}
      extra={
        <Space>
          <Button onClick={handleOnClose}>Cancel</Button>
          <Button type='primary' onClick={form.submit}>
            Search
          </Button>
        </Space>
      }
    >
      <Form layout='vertical' form={form} onFinish={handleSearchQuery}>
        <Form.Item
          initialValue={queryParams.listingType}
          name='listingType'
          label='listing Type'
        >
          <Select
            placeholder='select type'
            options={[
              { label: 'Rent', value: 'rent' },
              { label: 'Buy', value: 'buy' },
            ]}
          />
        </Form.Item>
        <Form.Item label='Amenities' name='amenities'>
          <div className='amenities-tags'>
            {tagsData.map<React.ReactNode>((tag) => (
              <Tag.CheckableTag
                key={tag}
                checked={selectedTags.includes(tag)}
                onChange={(checked) => handleChange(tag, checked)}
              >
                {tag}
              </Tag.CheckableTag>
            ))}
          </div>
        </Form.Item>
      </Form>
    </Drawer>
  );
}
