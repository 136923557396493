import { Button } from 'antd';
import { Link } from 'react-router-dom';

interface Props {
  path?: string;
}

export default function NotFoundPage({ path }: Props) {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '100vh',
        justifyContent: 'center',
      }}
    >
      <h1>404</h1>
      <h4>Sorry, this page does not exist.</h4>

      <Link to={path ? path : '/'}>
        <Button type='primary'>Go Home</Button>
      </Link>
    </div>
  );
}
