import { createSlice } from '@reduxjs/toolkit';
import {
  thunkCreateProperty,
  thunkDeleteProperty,
  thunkListProperties,
  thunkPatchProperty,
} from './thunks';
import { RootState } from 'src/app/core/store/store';
import { Property } from 'rentfree-api';

interface AdminPropertyState {
  error: any;
  isLoading: boolean;
  isDeleting: boolean;
  properties: Property[];
}

const initialState: AdminPropertyState = {
  error: null,
  isLoading: false,
  isDeleting: false,
  properties: [],
};

export const adminPropertySlice = createSlice({
  name: 'adminProperty',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(thunkCreateProperty.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(thunkCreateProperty.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(thunkCreateProperty.rejected, (state, action) => {
      state.error = action.error;
      state.isLoading = false;
    });

    builder.addCase(thunkListProperties.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(thunkListProperties.fulfilled, (state, action) => {
      state.isLoading = false;
      state.properties = action.payload;
    });
    builder.addCase(thunkListProperties.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });

    builder.addCase(thunkPatchProperty.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(thunkPatchProperty.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(thunkPatchProperty.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(thunkDeleteProperty.pending, (state) => {
      state.isDeleting = true;
    });
    builder.addCase(thunkDeleteProperty.fulfilled, (state) => {
      state.isDeleting = false;
    });
    builder.addCase(thunkDeleteProperty.rejected, (state) => {
      state.isDeleting = false;
    });
  },
});

export const selectProperties = (state: RootState) => state.adminProperty;
export const selectGetPropertiesData = (state: RootState) =>
  state.adminProperty.properties;
export const selectPropertiesError = (state: RootState) => state.adminProperty.error;
export const selectIsLoadingProperties = (state: RootState) =>
  state.adminProperty.isLoading;

export default adminPropertySlice.reducer;
