import * as React from 'react';
import {
  CloudOutlined,
  TeamOutlined,
  ShopOutlined,
  DashboardOutlined,
} from '@ant-design/icons';
import { Menu, MenuProps } from 'antd';
import { Link } from 'react-router-dom';
import { AppLogo } from 'src/app/core/components';
import useAuthUser from 'src/app/hooks/useAuthUser';
import { Roles } from 'src/app/routes/guards/roleProtectedRoute';

export default function AdminSiderMenu({ mode }: { mode: 'vertical' | 'inline' }) {
  const { user } = useAuthUser();

  const items: MenuProps['items'] = [
    {
      key: 'dashboard',
      icon: <DashboardOutlined />,
      label: <Link to={'/dashboard'}>Dashboard</Link>,
    },
    {
      key: 'users',
      icon: <TeamOutlined />,
      label: 'Users',
      children: [
        {
          key: '/users',
          label: <Link to={'/dashboard/users'}>View</Link>,
        },
        {
          key: '/users/create',
          label: <Link to={'/dashboard/users/create'}>Create</Link>,
        },
      ],
    },
    ...(Roles[user?.role!] !== 'client'
      ? [
          {
            key: 'properties',
            icon: <ShopOutlined />,
            label: 'Properties',
            children: [
              {
                key: 'properties',
                label: <Link to={'/dashboard/properties'}>View</Link>,
              },
              {
                key: 'properties/create',
                label: (
                  <Link to={'/dashboard/properties/create'}>
                    <span className='label'>Create</span>
                  </Link>
                ),
              },
            ],
          },
        ]
      : []),
    {
      key: 'saved-properties',
      icon: <CloudOutlined />,
      label: <Link to={'/dashboard/saved-properties'}>My Saved Properties</Link>,
    },
  ];

  return (
    <>
      <AppLogo />
      <hr />
      <Menu
        theme='light'
        mode={mode}
        defaultSelectedKeys={['4']}
        items={items}
        className='admin-sidebar-menu'
      />
    </>
  );
}
