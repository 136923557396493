import * as React from 'react';
import { ReactComponent as Logo } from 'src/app/asset/images/rentfree-logo.svg';
import EntryWrapper from 'src/app/core/components/entry-wrapper/EntryWrapper';
import LoginForm from './components/LoginForm';
import ThirdParties from 'src/app/core/components/entry-wrapper/ThirdParties';

import showMessage from 'src/app/core/utils/show-message';

import './Login.css';
import { useAppDispatch } from 'src/app/core/store/hooks';
import { thunkLogin } from '../store/thunks';
import { useNavigate } from 'react-router-dom';
import useAuthUser from 'src/app/hooks/useAuthUser';

interface Values {
  email: string;
  password: string;
}

export default function Login() {
  const { authLoading } = useAuthUser();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const onFinish = (values: Values) => {
    dispatch(thunkLogin(values))
      .unwrap()
      .then((data) => {
        showMessage('success', 'login successful');
        navigate('/dashboard', { replace: true });
      })
      .catch((error) => {
        showMessage('error', error.message ?? 'login failed');
      });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <EntryWrapper>
      <div className='login-container'>
        <a href='/'>
          <Logo />
        </a>

        <div className='login'>
          <p className='login-text'>Login</p>
          <p className='login__welcome-back'>Welcome back! Please enter your details.</p>
          <LoginForm
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            loading={authLoading === true}
          />
          <ThirdParties page='login' />
          <div className='signup'>
            Don’t have an account? <a href='/register'>Sign up</a>
          </div>
        </div>
      </div>
    </EntryWrapper>
  );
}
