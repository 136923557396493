import { Menu } from 'antd';
import { NavLink } from 'react-router-dom';

export default function RightMenu(props: React.ComponentProps<typeof Menu>) {
  return (
    <Menu {...props}>
      <Menu.Item key='about-us'>
        <NavLink to='/about-us'>About us</NavLink>
      </Menu.Item>
      <Menu.Item key='register'>
        <NavLink to='/register'>Register</NavLink>
      </Menu.Item>
      <Menu.Item key='login'>
        <NavLink className='login-button' to='/login'>
          Login
        </NavLink>
      </Menu.Item>
    </Menu>
  );
}
