import { Header } from "antd/lib/layout/layout";
import "./AppHeader.css";


function AppHeader({children, classNames}: {children: React.ReactNode, classNames?: string[]}) {

  return (
    <Header className={["header", "home-screen-padding", (classNames && [...classNames] )].join(' ')}>
      <div className="header-content">
        {children}
      </div>
    </Header>
  );
}

export default AppHeader;
