import { createSlice } from '@reduxjs/toolkit';
import {
  thunkGetAuthUser,
  thunkLogin,
  thunkLogout,
  thunkSignup,
  thunkVerifyEmail,
  thunkResetPassword,
} from './thunks';
import { User } from 'rentfree-api';
import { RootState } from 'src/app/core/store/store';

interface AuthState {
  isAuthenticated: boolean | null;
  user?: User | null;
  accessToken: string;
  refreshToken: string;
  error: any;
  isLoading: boolean | null;
}

const initialState: AuthState = {
  isAuthenticated: null,
  user: null,
  accessToken: '',
  refreshToken: '',
  error: null,
  isLoading: null,
};

// Slice
export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    clearAuthState: (state) => {
      state.isAuthenticated = false;
      state.user = null;
      state.accessToken = '';
      state.refreshToken = '';
      state.isLoading = false;
    },
    setAuthState: (state, action) => {
      state.isAuthenticated = true;
      state.user = action.payload.user;
      state.accessToken = action.payload.accessToken;
      state.refreshToken = action.payload.refreshToken;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(thunkLogin.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(thunkLogin.fulfilled, (state, action) => {
      state.isAuthenticated = true;
      state.user = action.payload.user;
      state.accessToken = action.payload.accessToken;
      state.refreshToken = action.payload.refreshToken;
      state.isLoading = false;
    });
    builder.addCase(thunkLogin.rejected, (state, action) => {
      state.error = action.error;
      state.isLoading = false;
    });
    builder.addCase(thunkLogout.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(thunkLogout.fulfilled, (state) => {
      state.isAuthenticated = false;
      state.user = null;
      state.accessToken = '';
      state.refreshToken = '';
      state.isLoading = false;
    });
    builder.addCase(thunkLogout.rejected, (state, action) => {
      state.error = action.error;
      state.isLoading = false;
    });
    builder.addCase(thunkGetAuthUser.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(thunkGetAuthUser.fulfilled, (state, action) => {
      state.user = action.payload.user;
      state.isLoading = false;
      state.isAuthenticated = true;
    });
    builder.addCase(thunkGetAuthUser.rejected, (state, action) => {
      state.error = action.error;
      state.isLoading = false;
      state.isAuthenticated = false;
    });
    builder.addCase(thunkSignup.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(thunkSignup.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(thunkSignup.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(thunkVerifyEmail.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(thunkVerifyEmail.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(thunkVerifyEmail.rejected, (state) => {});
    builder.addCase(thunkResetPassword.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(thunkResetPassword.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(thunkResetPassword.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

export const { setAuthState, clearAuthState } = authSlice.actions;
export const selectIsAuthenticated = (state: RootState) => state.auth.isAuthenticated;
export const selectUser = (state: RootState) => state.auth.user;
export const selectAccessToken = (state: RootState) => state.auth.accessToken;
export const selectRefreshToken = (state: RootState) => state.auth.refreshToken;
export const selectAuthError = (state: RootState) => state.auth.error;
export const selectAuthLoading = (state: RootState) => state.auth.isLoading;

export default authSlice.reducer;
