import { ReactComponent as Facebook } from 'src/app/asset/icons/facebook.svg';
import { ReactComponent as Google } from 'src/app/asset/icons/google.svg';
import './ThirdParties.css';
import { Button } from 'antd';

interface Props {
  page: string;
}

export default function ThirdParties({ page }: Props) {
  const api = process.env.REACT_APP_API_BASE_URL;

  return (
    <div>
      <div className='or'>
        <span>Or</span>
      </div>
      <div>
        <Button
          icon={<Google />}
          className='oauth-button'
          onClick={() => (window.location.href = `${api}/oauth/google`)}
        >
          <p>{page} with Google</p>
        </Button>
        <Button icon={<Facebook />} className='oauth-button'>
          <p>{page} with Facebook</p>
        </Button>
      </div>
    </div>
  );
}
