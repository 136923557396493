import { notification } from "antd";

export default function showMessage(
    type: keyof typeof notification,
    message: string,
    title: string | null = null,
) {
    notification[type]({
        message: title || type.charAt(0).toUpperCase() + type.slice(1),
        description: message,
        placement: 'top'
    })
}