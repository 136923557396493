import * as React from 'react';
import { Button, Form, Input } from 'antd';
import { useAppDispatch } from 'src/app/core/store/hooks';
import showMessage from 'src/app/core/utils/show-message';
import { thunkResetPassword } from '../../store/thunks';
import { useNavigate } from 'react-router-dom';
import { replaceSpacesWithPlus } from 'src/app/core/utils/helpers';

interface Props {
  token: string;
}

export default function ResetPasswordForm(props: Props) {
  const [isLoading, setIsLoading] = React.useState(false);
  const { token } = props;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const onFinish = (values: { password: string; confirmPassword: string }) => {
    setIsLoading(true);
    dispatch(
      thunkResetPassword({
        password: values.password,
        token: replaceSpacesWithPlus(token),
        withToken: true,
      })
    )
      .unwrap()
      .then((data) => {
        setIsLoading(false);
        navigate('/login');
        showMessage('success', 'password reset successfully');
      })
      .catch((error) => {
        setIsLoading(false);
        showMessage('error', error.message ?? 'Timeout');
      });
  };

  return (
    <div className='login'>
      <p className='login-text'>Forget Password</p>
      <p className='login__welcome-back'>Please kindly reset your password.</p>
      <div className='login-wrapper'>
        <Form name='basic' layout='vertical' onFinish={onFinish}>
          <Form.Item
            label='Password'
            name='password'
            rules={[{ required: true, message: 'Please input your password!' }]}
          >
            <Input.Password placeholder='Enter your password' className='input' />
          </Form.Item>
          <Form.Item
            label='Confirm Password'
            name='confirmPassword'
            rules={[
              { required: true, message: 'Please Confirm your password!' },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error('The new password that you entered do not match!')
                  );
                },
              }),
            ]}
          >
            <Input.Password placeholder='Confirm your password' className='input' />
          </Form.Item>
          <Form.Item>
            <Button
              type='primary'
              htmlType='submit'
              className='entry-button'
              loading={isLoading}
            >
              Reset password
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}
