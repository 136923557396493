import { createAsyncThunk } from '@reduxjs/toolkit';
import { Property, StateLga } from 'rentfree-api';
import { client } from 'src/app/services/client';
import { Paginated } from '@feathersjs/feathers';
import { GetPropertiesQuery } from 'src/app/core/types/property';

type LgaSearchParams = {
  lga?: string;
  state?: string;
  $search?: string;
};

export const thunkLgaSearch = createAsyncThunk<Paginated<StateLga>, LgaSearchParams>(
  'property/lgaSearch',
  async (payload) => {
    return client.service('lgas').find({
      query: {
        ...payload,
      },
    });
  }
);

export const thunkGetProperties = createAsyncThunk<
  Paginated<Property>,
  GetPropertiesQuery
>('properties/getProperties', async (queries: GetPropertiesQuery) => {
  const { minBed, maxBed, maxPrice, minPrice, location, ...query } = queries;
  const [state, lga] = location?.split('-') ?? [];

  const response = await client.service('properties').find({
    query: {
      price: { $gt: minPrice, $lt: maxPrice },
      bedrooms: { $gt: minBed, $lt: maxBed },
      state,
      ...(lga && { lga: lga }),
      ...query,
    },
  });

  return response;
});
