import { Button } from 'antd';
import { Link } from 'react-router-dom';

export default function Unauthorized() {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '100vh',
        justifyContent: 'center',
      }}
    >
      <h1>403 - Unauthorized</h1>
      <h4>You do not have permission to access this page.</h4>

      <Link to={'/dashboard'}>
        <Button type='primary'>Go Home</Button>
      </Link>
    </div>
  );
}
